@import "assets/scss/app.scss";

.react-datepicker-wrapper {
  display: inherit !important;
  padding: 0;
  border: 0;
}

.tooltip {
  pointer-events: none !important;
}

.file > input[type="file"] {
  opacity: 0;
}

.badge-red {
  background-color: #ff0000;
  color: white;
  width: 95px;
}

.badge-yelow {
  background-color: #ffff33;
  color: black;
  width: 95px;
}

.badge-green {
  background-color: #006600;
  color: white;
  width: 95px;
}

.badge-semaphore {
  width: 110px;
}

.badge-semaphore1 {
  width: 50px;
}

.content-loader {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  height: 150px;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ff9f40;
  background-color: #ff9f40;
  color: white;
  border-radius: 5px;
  text-align: center;
  width: 214px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file > label {
  width: 113px;
}

.progress {
  background-color: rgb(204, 201, 201);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fa.fa-sort {
  font-size: 16px;
  cursor: pointer;
}

.page-sidebar {
  background-color: #4466f2 !important;
}

/**=====================
     42. Timeline CSS Start
==========================**/
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      &.open {
        ~ .page-body {
          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1 {
                  &:after {
                    bottom: -66px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.timeline-small {
  .media {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .timeline-round {
      position: relative;
      width: 53px;
      height: 53px;
      border-radius: 100%;
      top: -18px;
      svg {
        position: absolute;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 18px;
        right: 0;
        margin: 0 auto;
        path,
        line,
        circle {
          color: $white;
        }
      }
      &.timeline-line-1 {
        &:after {
          position: absolute;
          content: "";
          background-color: #e9e9e9;
          width: 2px;
          height: 62px;
          bottom: -77px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      &.small-line {
        &:after {
          position: absolute;
          content: "";
          background-color: #e9e9e9;
          width: 2px;
          height: 19px;
          bottom: -34px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      &.medium-line {
        &:after {
          position: absolute;
          content: "";
          background-color: #e9e9e9;
          width: 2px;
          height: 53px;
          bottom: -68px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
    .media-body {
      h6 {
        font-weight: 600;
        margin-bottom: 30px;
        span {
          font-weight: normal;
          color: $light-text;
        }
      }
      p {
        width: 80%;
      }
    }
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
/**=====================
     42. Timeline  CSS Ends
==========================**/

.multi-select {
  --rmsc-height: 30px !important;
}

.accordion-header {
  position: relative;
  padding: 0.75rem;
  height: 4em;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  color: black;
  cursor: pointer;
}

.tabbed-card {
  ul {
    position: absolute;
    right: 15px;
    top: 22px;
  }
}
